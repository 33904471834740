module.exports = [{
      plugin: require('/Users/shaundawson/General_Assembly/Code/reactblog/node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/Users/shaundawson/General_Assembly/Code/reactblog/node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/Users/shaundawson/General_Assembly/Code/reactblog/node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-46106886-2"},
    },{
      plugin: require('/Users/shaundawson/General_Assembly/Code/reactblog/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
